import { useEffect, useState } from "react";
import { ButtonDefinitions, HeaderDefinitions } from "../../configs/HeaderMeta";
import { APP_CONSTANTS  } from "../../configs/Utils";
import { SearchBar, SearchResults } from "../widgets";

const EXTRA_BTN_DEFS: any = {
    'info': {name: 'Details', icon: 'info.png'},
}


const Header = (props: { component: string, extraBtns?: any, title?: string, count?: number, onHeaderBtnClick?: any, searchData?: any }) => {

    const [headerDef, setHeaderDef] = useState<any>({});
    const role = localStorage.getItem(APP_CONSTANTS.ROLE) ?? ''
    const [btnDef, setBtnDef] = useState([]);
    const [extraBtns, setExtraBtn] = useState<any>([]);
    const [screenWidth] = useState(window.innerWidth);
    const [searchResults, setSearchResults] = useState<any>([]);
    const [searchOpen, setSearchOpen] = useState(false);

    const _toggleSearch = () => {
        setSearchOpen(!searchOpen);
    }
    const _onSearchConsignment = (e: any) => {
        if (!e) {
            setSearchResults([]);
            _toggleSearch();
            return;
        }
        props.onHeaderBtnClick('search', e);
    }

    useEffect(() => {
        setHeaderDef(HeaderDefinitions[props.component]);
        setBtnDef(ButtonDefinitions[role][props.component]);
    }, [props.component])

    useEffect(() => {
        if (screenWidth < 1400 && props.extraBtns) {
            setExtraBtn(props.extraBtns)
            // setExtraBtn(EXTRA_BTN)
        }
    }, [props.extraBtns])

    useEffect(() => {
        if (props.searchData) {
            setSearchResults(props.searchData)
        }
    }, [props.searchData])


    return (
        <div className='contentHeader'>
            {
                headerDef &&
                <div className="flex" style={{ 'alignItems': 'center' }}>
                    {headerDef.backBtnTitle && <div className="backBtn" onClick={() => props.onHeaderBtnClick('back')}>
                        <img className="backIcon" src={APP_CONSTANTS.IMAGE_PATH + 'back.png'} />
                        <span>{headerDef.backBtnTitle}</span>
                    </div>
                    }
                    <div className='contentHeaderGroup'>
                        <div className='contentHeaderTitle'>
                            <div className="text">{props.title ? props.title : headerDef.title}</div>
                            {extraBtns.length > 0 &&
                                extraBtns.map((btn: any) => (
                                    <img key={btn} src={APP_CONSTANTS.IMAGE_PATH + EXTRA_BTN_DEFS[btn].icon} className="contentHeaderIcon" onClick={() => props.onHeaderBtnClick(btn)} alt={EXTRA_BTN_DEFS[btn].name}/>
                                ))
                            }
                        </div>
                        {headerDef.subtitleEntity && <div className='contentHeaderSubtitle'>{props.count} {headerDef.subtitleEntity}</div>}
                    </div>
                </div>
            }
            <div className="flex" style={{alignItems: 'center'}}>
                {
                    headerDef && headerDef.hasSearch && searchResults &&
                    <div className='contentHeaderGroup searchWrap'>
                        <SearchBar component={props.component} onSearch={(e: any) => props.onHeaderBtnClick('search', e)} />
                        { searchResults && <SearchResults data={searchResults} onHeaderBtnClick={props.onHeaderBtnClick}/> }
                    </div>
                }
                {
                    headerDef && btnDef &&
                    btnDef.map((btn: string, index: number) => (
                        <div key={index} className='contentHeaderGroup'>
                            <div className='createHeadertBtn' key={index} onClick={() => props.onHeaderBtnClick(btn)}>{btn}</div>
                        </div>
                    ))
                }
                {
                    headerDef && headerDef.hasSearch && searchResults &&
                    <div className='mobilesearchWrap'>
                        <img src={APP_CONSTANTS.IMAGE_PATH + 'search.png'} className="searchIcon" onClick={() => _toggleSearch()} alt='search'/>
                    </div>
                }
                {
                    headerDef && headerDef.hasSearch && searchOpen && searchResults &&
                    <div className='mobilesearchContentWrap'>
                        <div className="flex justify-content-between" style={{alignItems: 'center'}}>
                            <SearchBar component={props.component} onSearch={(e: any) => _onSearchConsignment(e)} />
                            <img src={APP_CONSTANTS.IMAGE_PATH + 'cancel.png'} style={{width:'25px',height: '25px', marginLeft: '5px'}} onClick={() => _toggleSearch()} alt='close'/>
                        </div>
                        { searchResults && <SearchResults data={searchResults} onHeaderBtnClick={props.onHeaderBtnClick}/> }
                    </div>
                }
            </div>
        </div>
    );
};

export default Header;








