import { APP_CONSTANTS } from "../../../../configs/Utils";

const FarmerModal = (props: { handleClose: any, open: boolean, farmer: any }) => {
    const farmer = props.farmer ?? props.farmer;

    return (
        <>
            {farmer &&
                <div className='formContainer'>
                    <div className="formHeader">
                        <div className="formHeaderTitle">
                            <img src={APP_CONSTANTS.IMAGE_PATH + 'user.png'} /><b>{farmer.farmerName}</b>
                        </div>
                    </div>
                    <div className='formBody'>
                        {/* <div className='attr'><img src={APP_CONSTANTS.IMAGE_PATH + 'user.png'} /><b>{farmer.farmerName}</b></div>
                        <br /> */}
                        {farmer.address &&
                            <div className='attr'><img src={APP_CONSTANTS.IMAGE_PATH + 'location.png'} /><b>{farmer.address}</b></div>
                        }

                        <div className='attr'>No. of Farms : <b>{farmer.farmCount}</b></div>

                        {farmer.mobile &&
                            <div className='attr'>Contact No. : <b>{farmer.mobile}</b></div>
                        }
                        {farmer.userId &&
                            <>
                                <div className='attr'>{farmer.userId?.idType} : <b>{farmer.userId?.idvalue}</b></div>
                            </>
                        }
                        {farmer.apedaReg &&
                            <div className='attr'>APEDA Registration No. : <b>{farmer.apedaReg}</b></div>
                        }

                    </div>
                </div>
            }
        </>
    )
}

export default FarmerModal