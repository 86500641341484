import React, { useEffect, useRef } from 'react'
import { RJSFSchema } from '@rjsf/utils';
import { FormDesign } from '../../components';

const Registration = (props: { type: string, data?: any, uiSchema: RJSFSchema, schema: RJSFSchema, onClose?: any, onFormSubmit: any, onFormChange?: any, children?: any }) => {

  const [reqBody, setReqData] = React.useState({});
  const [schema, setSchema] = React.useState<any>();
  const [uiSchema, setUiSchema] = React.useState<any>();
  const formRef: any = useRef<any>(null);

  const onFormSubmit = () => {
    if (formRef.current.checkValidation()) {
      props.onFormSubmit(reqBody);
    }

  }

  const onFormChange = (data: any) => {
    setReqData({ ...data })
    if (props.onFormChange)
      props.onFormChange(data)
  };

  // useEffect(() => {
  //   setReqData(props.data);
  // }, [props.data])

  useEffect(() => {
    setSchema(props.schema);
    setUiSchema(props.uiSchema);
  }, [props.schema, props.uiSchema])

  return (
    <div className='formContainer dialogFormContainer'>
      <div className="formHeader">
        <div className="formHeaderTitle">{props.type}</div>
        {props.onClose && <button type="button" className="btn-close" onClick={() => props.onClose()}></button>}
      </div>
      <div className="formBody">
        <FormDesign
          ref={formRef}
          schema={schema}
          uiSchema={uiSchema}
          formdata={props.data}
          submitted={() => { }}
          change={onFormChange} />
        <br />
        {props.children}
        <div className='formButtonGroup'>
          <div className="formBtn primaryBtn" onClick={onFormSubmit}>Create Now</div>
        </div>
      </div>
    </div>
  )
}

export default Registration