import { useEffect, useState } from 'react';
import './allocationMeta.scss';
import { formatDate } from '../../../../configs/Utils';

const AllocationMeta = (props: {data: any}) => {

    // const [collected, setCollected] = useState(0);

    // useEffect(() => {
    //     if (props.data.collections.length > 0) {
    //         let q = 0;
    //         props.data.collections.forEach((collection: any) => {
    //             q += collection.quantity.amount;
    //         });
    //         setCollected(q);
    //     }
    // }, [props.data])

    return (
        <div className="allocationMeta">
            <div className='metaGroup'>
                <div className='metaItem'>Allocated to : <b>{props.data?.assignee.name ? props.data?.assignee.name : props.data?.assignee.idvalue}</b></div>
                {/* <div className='metaItem'>Allocated : <b>{props.data?.targetQty.amount} {props.data?.targetQty.unit}</b></div> */}
                <div className='metaItem'>Expected Completion Date : <b>{formatDate(props.data?.collectionCompletionDate)}</b></div>
            </div>
            <div className='metaGroup'>
                {/* { props.data?.completed && <img className='completedCard' src={APP_CONSTANTS.IMAGE_PATH + 'completed.png'} alt="complete" /> } */}
                <div className='quantityGroup'>
                    <div className='quantityInfo'>
                        {/* <div className='label'>{props.data?.completed ? 'Completed' : 'Collection'}</div>
                        { !props.data?.completed && <div className='value activeColor'><b>{collected}</b> {props.data?.targetQty.unit}</div>  }  
                        { props.data?.completed && <div className='value completeColor'><b>{props.data?.targetQty.amount}</b> {props.data?.targetQty.unit}</div>  }    */}
                        <div className='label'>Allocated</div>
                        <div className='value completeColor'><b>{props.data?.targetQty.amount}</b> {props.data?.targetQty.unit}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllocationMeta;