import { useState, useEffect } from "react";
import { ConsignmentSchema, ConsignmentUiSchema } from "../../../configs/Schemas";
import { APP_CONSTANTS, getCurrentRole } from "../../../configs/Utils";
import { ViewDmsDoc } from "../../../shared/components";
import Registration from "../../../shared/containers/registration/Registration";
import { useModal } from "../../../shared/contexts/modalContext";
import { DATA_MGMT_API, TRADE_MGMT_API, MessageService } from "../../../shared/services";
import { MasterAPI } from "../../../shared/services/MasterAPI";


const ContractForm = (props: { onContractFormEmit: any }) => {

    const [reqData, setReqData] = useState<any>({})
    const [schema, setschema] = useState<any>(ConsignmentSchema)
    const [formUISchema, setFormUISchema] = useState<any>();
    const { openModal, closeModal } = useModal();
    const [checked, setchecked] = useState(false)
    const SOP_PDF = `SOP_Dashehari_Chousa.pdf`;


    useEffect(() => {
        _loadData();

    }, [])

    const _loadData = async () => {
        let role = getCurrentRole();
        let values = role === 'EXPORTER' ? await MasterAPI.getContractUISchema('TRADER') : await MasterAPI.getContractUISchema('EXPORTER')
        // role === 'EXPORTER' ? await _fetchUsers('TRADER') : await _fetchUsers('EXPORTER');
        // _fetchVarieties();
        _fetchCountries();

        _setValuesForUISchema(values)

        // setFormUISchema(uiSchemaobj)
    }

    const _setValuesForUISchema = (res: any) => {
        let uiSchemaobj = { ...ConsignmentUiSchema }
        uiSchemaobj = { ...uiSchemaobj, contractParty: { ...uiSchemaobj.contractParty, "ui:options": [res[0]] }, item: { ...uiSchemaobj.item, itemId: { ...uiSchemaobj.item.itemId, "ui:options": [res[1]] } } }
        setFormUISchema(() => { return uiSchemaobj });
    }

    const _fetchCountries = () => {
        DATA_MGMT_API.getCountries().then((res: any) => {
            if (res) {
                formatCountryList(res);
            }
        })
    }

    const formatCountryList = (res: any) => {
        let schemaobj = { ...schema };
        if (schemaobj.properties) {
            schemaobj.properties.destinationCountryCode.oneOf = formatField(res);
        }
        setschema(() => { return schemaobj });
    }

    const formatField = (res: any) => {
        let arr = res.map((a: any) => {
            let obj = JSON.parse(JSON.stringify(a));
            obj.const = a.id;
            obj.title = a.name;
            delete obj.id;
            return obj;
        })
        return arr;
    }
    const generatePorts = async (destCountryCode: string, mode: string) => {
        let schemaobj = { ...schema };
        let ports;
        if (schemaobj.properties) {
            let country = schemaobj.properties.destinationCountryCode.oneOf.find((a: any) => a.const === destCountryCode);
            ports = country ? formatField(country.ports[mode]) : [];
        }
        return ports;
    }

    const onFormSubmit = (data: any) => {
        if (checked) {
            data = { ...data, trader: '', exporter: '' };
            let role = getCurrentRole();
            role === 'EXPORTER' ? data.trader = data.contractParty : data.exporter = data.contractParty;
            delete data.contractParty;

            TRADE_MGMT_API.createTradeContract(data).then((res: any) => {
                MessageService.setStatus({ type: 'success', text: 'Consignment created successfully!' });
                props.onContractFormEmit('pending');
            })
        }
        else {
            MessageService.setStatus({ type: 'error', text: 'Please accept the declaration on SOP' });
        }
    }

    const _updatedSchema = (ports: any, portType: string, schema: any) => {
        let schemaobj = { ...schema };
        let properties = JSON.parse(JSON.stringify(schemaobj.properties));
        properties = { ...properties, [portType]: { oneOf: ports, type: 'string', title: portType === 'destinationPort' ? 'Destination Port Name' : 'Origin Port Name' } };
        // setschema({ ...schemaobj, properties });
        return { ...schemaobj, properties }
    }

    const onFormChange = async (data: any) => {
        let oldData = { ...reqData };
        let originPorts, destinationPorts;
        let schemaobj = { ...schema };
        if (data.transportMode && data.transportMode != oldData.transportMode) {
            originPorts = await generatePorts('IN', data.transportMode)
            schemaobj = _updatedSchema(originPorts, 'originPort', schemaobj);
        }
        if (data.destinationCountryCode && data.transportMode && (data.destinationCountryCode != oldData.destinationCountryCode || data.transportMode != oldData.transportMode)) {
            destinationPorts = await generatePorts(data.destinationCountryCode, data.transportMode)
            schemaobj = _updatedSchema(destinationPorts, 'destinationPort', schemaobj);
        }
        setschema({ ...schemaobj });
        setReqData(data);
    };


    const _toViewDoc = (file: any) => {
        openModal(<ViewDmsDoc local={true} file={file} handleClose={() => closeModal()} download={_clickToDownload} extension={".pdf"}/>, 'md');
    }

    const _clickToDownload = () => {
        const link = document.createElement("a");
        link.href = `${APP_CONSTANTS.PDF_PATH}${SOP_PDF}`;
        link.download = "sop.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div className='offcanvas show' role="dialog" tabIndex={-1}>
            {schema && formUISchema &&
                <Registration
                    onFormSubmit={onFormSubmit}
                    onFormChange={onFormChange}
                    onClose={props.onContractFormEmit}
                    data={reqData}
                    schema={schema}
                    uiSchema={formUISchema}
                    type='Create Consignment'>
                    <br />
                    <div className='formTerms'>
                        <input type="checkbox" onChange={(v) => setchecked(v.target.checked)} />
                        <div className="termsText">
                            I have read and understood the <u onClick={() => _toViewDoc(SOP_PDF)}>SOP</u> for the export of mangoes
                        </div>
                    </div>
                    <br />
                </Registration>
            }
        </div>
    )
}

export default ContractForm;