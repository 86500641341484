import { APP_CONSTANTS, formatDate } from '../../../../configs/Utils';
import { useState, useEffect } from 'react';
import './consignmentDetails.scss';
import { StatusCard } from '../../../../shared/widgets';

const ConsignmentDetails = (props: {consignment: any}) => {
    const [consignment, setConsignment] = useState({} as any);
    useEffect(() => {
        setConsignment(props.consignment);
    }, [props.consignment]);

    return (
        <>
            {
                consignment.id && 
                <div className='consignmentDetailsContainer'>
                    <div className='id'>
                        <div className='flexColumn'>
                            <div>Consignment ID</div>
                            <div className='value'>#{consignment.id}</div>
                        </div>
                        <div className='flex'>
                            { consignment.delayed && <div className='isDelayed'>Delayed</div> }
                        </div>
                    </div>

                    <div className='statusWrap'>
                        <StatusCard status={consignment.status} count={consignment.orderQty.amount + ' ' + consignment.orderQty.unit} tempCount={consignment.metQuantity} entity='quantity'/>
                    </div>

                    {/* <div className='statusMeta'>
                        <h6>STAGE : <b>{consignment.status}</b></h6>
                    </div> */}

                    <div className='exportMeta'>
                        <div className='variety'>
                            <div className='flexColumn'>
                                Item
                                <div className='name'>{consignment.item.itemType}</div>
                            </div>
                            <div className='flexColumn'>
                                Variety
                                <div className='name'>{consignment.item.itemName}</div>
                            </div>
                        </div>

                        <div className='exporter'>
                            {/* <div className='name'>{consignment.exporter.idvalue}</div> */}
                            <div className='name'>{consignment.assigneeName}</div>

                            <br/>
                            {/* <div className='country'>Origin : <b>{consignment.originCountryCode}</b></div> */}
                            <div className='country'>Origin : <b>{consignment.originCountry}</b></div>
                            <div className='mode'>Origin Port : <b>{consignment.originPortName}</b></div>
                            <br/>
                            {/* <div className='country'>Destination : <b>{consignment.destinationCountryCode}</b></div> */}
                            <div className='country'>Destination : <b>{consignment.destinationCountry}</b></div>
                            <div className='mode'>Destination Port : <b>{consignment.destinationPortName}</b></div>
                            <div className='mode'>Mode of transport : <b>{consignment.transportMode}</b></div>
                        </div>


                        <div className='dates'>
                            <div className='contract'>Contract Date : <b>{formatDate(consignment.orderPlacedDate)}</b></div>
                            <div className='delivery'>Delivery Date : <b>{formatDate(consignment.targetFulfillmentDate)}</b></div>
                        </div>
                    </div>
                </div>
            }
        </>
        
    );
}

export default ConsignmentDetails;