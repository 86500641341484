import React, { useRef } from 'react';
import { FarmSchema, FarmUiSchema } from '../../../configs/Schemas';
import FormDesign from '../../../shared/components/FormDesign';
import { RJSFSchema } from '@rjsf/utils';
import { MessageService, fieldOfficersHandler } from '../../../shared/services/helper.services';

const FarmAllocationForm = (props: { onFarmAllocation: any, data?: any }) => {

    const [schema, setschema] = React.useState(FarmSchema)
    const [formUISchema, setFormUISchema] = React.useState<RJSFSchema>(FarmUiSchema);
    const formRef: any = useRef<any>(null);
    const [reqData, setReqData] = React.useState({} as any);
    const [officers, setOfficers] = React.useState([] as any);

    const onSubmit = () => {
        if (formRef.current.checkValidation()) {
            if (reqData.assignee)
                reqData.assignee = officers.find((e: any) => e.id === reqData.assignee);
            props.onFarmAllocation(reqData)
        }
    }
    const onChange = (data: any) => {
        
        setReqData({ ...data })
    }

    React.useEffect(() => {
        getAllOfficers()
    }, [])

    const setOfficersAndUpdateSchema = (res: any) => {
        setOfficers(res);
        let obj = {
            "assignee": {
                "ui:widget": "autocomplete",
                "ui:options": [res],
            },
        }
        let r = { ...FarmUiSchema, ...obj }
        setFormUISchema(r)
    }

    const getAllOfficers = () => {
        fieldOfficersHandler.subscribe((res) => {
            if (res) {
                setOfficersAndUpdateSchema(res);
            } else {
                MessageService.getAllFieldOfficers();
            }
        })
    }


    return (
        <div className="formContainer">
            <div className="formHeader">
                <div className="formHeaderTitle">Officer Allocation</div>
            </div>
            <div className='formBody'>
                <FormDesign
                    ref={formRef}
                    schema={schema}
                    uiSchema={formUISchema}
                    submitted={() => { }}
                    change={onChange}
                />

                <div className='formButtonGroup'>
                    <div className="formBtn primaryBtn" onClick={onSubmit}>Allocate</div>
                    <div className="formBtn secondaryBtn" onClick={() => props.onFarmAllocation()}>Cancel</div>
                </div>

            </div>
        </div>
    )
}

export default FarmAllocationForm;