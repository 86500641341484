import { APP_CONSTANTS, CONSIGNMENT_STATUS, INVITES_STATUS } from "../../../configs/Utils";
import './statusCard.scss';

const StatusCard = (props: { status: string, count: any, tempCount?: number, entity: 'consignment' | 'quantity' | 'invites', onCardClick?: any }) => {

    const _getImage = () => {
        let src = APP_CONSTANTS.IMAGE_PATH;
        if (props.entity === 'consignment' || props.entity === 'invites')
            switch (props.status) {
                case 'NEW':
                    return src + 'consignment-white.png';
                case 'ACTIVE':
                    return src + 'active.png';
                case 'COMPLETED':
                    return src + 'completed.png';
                default:
                    return src + 'consignment-white.png';
            }
        if (props.entity === 'quantity')
            return src + 'weight.png';
    }

    return (
        <div className={'statusCard ' + (props.status === 'NEW' ? 'newCard' : props.status === 'COMPLETED' ? 'completedCard' : 'activeCard')} onClick={() => props.onCardClick(props.status)}>
            <div className="flex">
                <div className="statusImgWrap">
                    <img src={_getImage()} />
                </div>
            </div>
            {
                props.entity === 'quantity' &&
                <div className="flexColumn value">
                    <div className='info'>Quantity</div>
                    {props.status === 'ACTIVE' && props.tempCount && <div className='metcount'><b>{props.count - props.tempCount} Kg</b> left of </div>}
                    <div className='count'>{props.count}</div>
                </div>
            }
            {
                props.entity === 'consignment' &&
                <div className="flex statusInfo">
                    <div className='count'>{props.count}</div>
                    <div className='info'>{CONSIGNMENT_STATUS[props.status]} Consignments</div>
                </div>
            }
            {
                props.entity === 'invites' &&
                <div className="flex statusInfo">
                    <div className='count'>{props.count}</div>
                    <div className='info'>{INVITES_STATUS[props.status]} Request</div>
                </div>
            }
        </div>
    );
}

export default StatusCard;