import { PdfViewer } from 'prm-react-pdf-viewer'
import 'prm-react-pdf-viewer/dist/index.css'

// Import styles
import { useEffect, useState } from 'react';
import { DmsAPI } from '../services';
import { APP_CONSTANTS } from '../../configs/Utils';


const ViewDmsDoc = (props: { file: any, handleClose: any, local?: boolean, url?: boolean, download?: any, extension?: string }) => {

    const [docurl, setdocurl] = useState<any>(null)

    // useEffect(() => {
    //     DmsAPI.downloadDocUrl(props.file).then(res => {
    //         console.log(typeof (res), res)
    //         setdoc(res)
    //     })
    // }, [])

    const _getDocUrl = async () => {
        let code = props.file;
        let ext = props.extension;
        if (props.local) {
            setdocurl(`${APP_CONSTANTS.PDF_PATH}${code}`);
            return;
        }
        if (props.url) {
            setdocurl(code);
            return;
        }
        let f = await DmsAPI.fetchAuthenticatedFile(code, ext);
        setdocurl(f);
    }

    useEffect(() => {
        if (props.file) {
            _getDocUrl();
        }
    }, [props.file])

    return (
        <div className='dmsDocViewer'>
            <div className='docviewheader'>
                <b>{props.file}</b>
                {props.download && <img  onClick={props.download} src={APP_CONSTANTS.IMAGE_PATH + 'download.png'} />}
            </div>
            <div className='pdfContainer'>
                {
                    docurl &&
                    <>
                        {   props.extension?.includes('pdf') ?
                            <PdfViewer options={{docUrl: docurl, initialZoomPercentage:100}}/>
                            : 
                            <img src={docurl} />
                        }
                    </>
                }
                
            </div>
        </div>
        // <div className='formContainer'>
        //     <div className="formHeader">
        //         <div className="formHeaderTitle" onClick={props.download}>Document : <b>{props.file}</b>  &nbsp;
        //             {props.download && <img src={APP_CONSTANTS.IMAGE_PATH + 'download.png'} className='modalCancel' />}
        //         </div>
        //     </div>

        //     <div className='formBody pdfContainer' style={{width:'400px'}}>
        //         {props.extension === '.pdf' ?
        //             // <iframe
        //             //     width={"100%"}
        //             //     height={"100%"}
        //             //     src={props.url ? props.file : _getDocUrl(props.file)}
        //             // >
        //             // </iframe>
        //             <PdfViewer options={{docUrl: props.url ? props.file : _getDocUrl(props.file), initialZoomPercentage:100}}/>
        //             :
        //             <img src={_getDocUrl(props.file)} />
        //         }
        //     </div>
        // </div>
    )
}

export default ViewDmsDoc
