import React, { Suspense, lazy, useState } from 'react';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PATHS_CONSTANT } from './configs/Utils';
import { loaderSubject, statusSubject, statusSubjectHandler } from './shared/services/helper.services'
import { Loader, LoadingPage, SPAContainer, AlertComponent, MapTalks } from './shared/components/index'
import Welcome from './shared/containers/welcome/welcome';
import QRView from './shared/containers/qr/qRView';
import { ModalProvider } from './shared/contexts/modalContext';
import { DrawerProvider } from './shared/contexts/drawerContext';
import { Footer, NotificationDashboard } from './shared/containers';

const SignUp = lazy(() => import('./shared/containers/registration/userRegistration'))
const Login = lazy(() => import('./shared/containers/login/Login'))
const ForgotPassword = lazy(() => import('./shared/containers/generatePassword/generatePassword'))
const TradeRouter = lazy(() => import('./modules/TradeManagementModule/TradeRouter'));
const FarmRouter = lazy(() => import('./modules/EntityManagementModule/routers/farmRouter'));
const TraderRouter = lazy(() => import('./modules/EntityManagementModule/routers/entityRouter'));
const ExporterRouter = lazy(() => import('./modules/EntityManagementModule/routers/entityRouter'));
const FarmerRouter = lazy(() => import('./modules/EntityManagementModule/routers/farmerRouter'));
const OfficerRouter = lazy(() => import('./modules/EntityManagementModule/routers/entityRouter'));

function App() {
  let status_subscription: any;
  let subscription_handler: any;
  const [isLoading, setIsLoading] = useState(false);
  const [statusMsg, setStatusMsg] = useState<any>();
  const [statushanderTrigger, setStatusHandlerTigger] = useState<any>(true);

  React.useEffect(() => {

    status_subscription = statusSubject.subscribe((data: any) => {
      if (statushanderTrigger && data && Object.keys(data).length !== 0) {
        setStatusMsg(data);
      }
    })

    subscription_handler = statusSubjectHandler.subscribe((data: any) => {
      setStatusHandlerTigger(data)
      if (!data && status_subscription) {
        setStatusMsg(null);
      }
    })

    loaderSubject.subscribe((data: any) => {
      setIsLoading(data);
    })

  }, [])

  let loader = <></>;
  if (isLoading) {
    loader = <Loader />;
  }


  return (
    <>
      {loader}
      {statusMsg && <AlertComponent message={statusMsg.text} type={statusMsg.type} />}
      <Suspense fallback={<LoadingPage />}>
        <ModalProvider>
          <DrawerProvider>
            <BrowserRouter basename='/'>
              <Routes>
                <Route path='/' element={<Welcome />} />
                <Route path='signup' element={<SignUp />} />
                <Route path='login' element={<Login />} />
                <Route path='generatePassword' element={<ForgotPassword />} />
                <Route path={`/${PATHS_CONSTANT.POST_LOGIN}/*`} element={<SPAContainer />} >
                  <Route path='consignments/*' Component={TradeRouter} />
                  <Route path='farms/*' Component={FarmRouter} />
                  <Route path='exporters/*' Component={ExporterRouter} />
                  <Route path='traders/*' Component={TraderRouter} />
                  <Route path='farmers/*' Component={FarmerRouter} />
                  <Route path='fieldOfficers/*' Component={OfficerRouter} />
                  <Route path='mapView' element={<MapTalks />} />
                  <Route path='notifications' element={<NotificationDashboard />} />
                </Route>
                <Route path='/qr/:id' element={<QRView />} />
                <Route path={`/${PATHS_CONSTANT.ADMIN}/*`} element={<SPAContainer />} >
                  {/* <Route path='consignments/*' Component={TradeRouter} />
                  <Route path='farms/*' Component={FarmRouter} />
                  <Route path='exporters/*' Component={ExporterRouter} />
                  <Route path='traders/*' Component={TraderRouter} />
                  <Route path='farmers/*' Component={FarmerRouter} />
                  <Route path='fieldOfficers/*' Component={OfficerRouter} />
                  <Route path='mapView' element={<MapTalks />} />
                  <Route path='notifications' element={<NotificationDashboard />} /> */}
                </Route>
                <Route path={`/${PATHS_CONSTANT.EXPORTER}/*`} element={<></>} />
                <Route path={`/${PATHS_CONSTANT.PROJECT_STAFF}/*`} element={<></>} />
              </Routes>

            </BrowserRouter>
            <Footer />
          </DrawerProvider>
        </ModalProvider>
      </Suspense>
    </>
  );
}

export default App;
