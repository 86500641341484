import { _get, _patch, _post, _put } from "./API";

const baseURL = process.env.REACT_APP_BASE_URL;
const subpath = 'admin';

export const ADMIN_MGMT_API = {
    approveUser: async (data: any) => {
        return await _patch(`${baseURL}/${subpath}/users/approve`, data);
    },
    fetchInactiveUsers: async (userType: string) => {
        return _get(`${baseURL}/${subpath}/users/inactive/${userType}`);
    },
    fetchActiveUsers: async (userType: string) => {
        return _get(`${baseURL}/${subpath}/users/active/${userType}`);
    }
}