import React, { SyntheticEvent, useState } from 'react'
import { StatusCard } from '../../widgets';
import { APP_CONSTANTS, getCurrentRole, ROLES } from '../../../configs/Utils';
import { MessageService, UserMngmntAPI } from '../../services';
import { Email, VerifiedUser } from '@mui/icons-material';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PinDropIcon from '@mui/icons-material/PinDrop';
import './inviteDashboard.scss';
import { ADMIN_MGMT_API } from '../../services/AdminManagementAPI';

const InviteDashboard = (props: { invitesArr: any, approvalStatus: any }) => {

    return (
        // <div className='contentContainer'>
        <div className='contentBody displayBlock scrollable'>

            {/* <div className='columnClass'>
                    {props.pendingInvites && <InviteList list={props.pendingInvites} status={'ACTIVE'} approvalStatus={props.approvalStatus} />}
                </div>
                <div className='columnClass'>
                    {props.approvedInvites && <InviteList status={'COMPLETED'} list={props.approvedInvites} />}
                </div> */}
            {
                props.invitesArr.length > 0 && props.invitesArr.map((user: any, index: number) => (
                    <div className='pendingCardInline inline' key={index}>
                        <PendingApprovalCard user={user} approvalStatus={props.approvalStatus} />
                    </div>
                ))
            }
            {/* <br />
            {
                props.approvedInvites.length > 0 && props.approvedInvites.map((user: any, index: number) => (
                    <div className='pendingCardInline inline' key={index}>
                        <PendingApprovalCard user={user} />
                    </div>
                ))
            } */}

        </div>
        // </div>
    )
}

export default InviteDashboard


// const InviteList = (props: { list: any, status: string, approvalStatus?: any }) => {
//     const [screenWidth, setScreenWidth] = useState(window.innerWidth);

//     const _approve = (e: SyntheticEvent, id: string) => {
//         UserMngmntAPI.acceptLinkRequest({ "userIds": [id] }).then((response: any) => {
//             MessageService.setStatus({ type: 'success', text: 'Request accepted!' });
//             props.approvalStatus(true);
//         });
//     }

//     const _isPendingWithMe = () => {
//         let role = getCurrentRole();
//         return role === ROLES['TRADE_ADMIN'] ? true : false
//     }

//     return (
//         <>
//             {props.list &&
//                 <div className='list'>
//                     <div className='listHeader'>
//                         <StatusCard status={props.status} count={props.list.length} entity='invites' />
//                     </div>
//                     <div className='listInner filterlessCard'>
//                         {
//                             (screenWidth < 1000 || screenWidth >= 1000) && props.list &&
//                             <>
//                                 {
//                                     props.list.length > 0 && props.list.map((user: any, index: number) => (
//                                         <div className='inviteCard' key={index}>

//                                             <div className='inviteContent'>
//                                                 <div className='inviteHeader'>
//                                                     <img src={APP_CONSTANTS.IMAGE_PATH + 'user.png'} />
//                                                     <div className='headerTitle ellipsis'>{user.name}</div>
//                                                 </div>
//                                                 <div className='inviteBody'>
//                                                     <div className='inviteMeta inline'>
//                                                         <div className='meta'>{user.mobile}</div>
//                                                         <div className='meta'>{user.email}</div>
//                                                         <div className='meta'>{user.apedaRCMC}</div>
//                                                         <div className='meta'>{user.address}</div>
//                                                     </div>
//                                                 </div>
//                                                 {
//                                                     props.approvalStatus && <div className='inviteFooter'>
//                                                         {_isPendingWithMe() === true ?
//                                                             <div className='primaryBtn' onClick={(e) => _approve(e, user.id)}>Approve</div>
//                                                             :
//                                                             <div className='statusDesc'>Approval Pending</div>
//                                                         }
//                                                     </div>
//                                                 }
//                                             </div>
//                                         </div>
//                                     ))
//                                 }
//                                 {
//                                     props.list.length === 0 &&
//                                     <div className='emptyRequest'>
//                                         <div className='msg'>No request!</div>
//                                     </div>
//                                 }
//                             </>
//                         }
//                     </div>
//                 </div>
//             }
//         </>
//     )
// }



const PendingApprovalCard = (props: { user: any, approvalStatus?: any }) => {
    const user = props.user

    const _approve = (e: SyntheticEvent, id: string) => {
        UserMngmntAPI.acceptLinkRequest({ "userIds": [id] }).then((response: any) => {
            MessageService.setStatus({ type: 'success', text: 'Request accepted!' });
            props.approvalStatus(true);
        });
    }

    const _approveAdmin = (e: SyntheticEvent, id: string) => {
        ADMIN_MGMT_API.approveUser({ "userIds": [id] }).then((response: any) => {
            MessageService.setStatus({ type: 'success', text: 'Request accepted!' });
            props.approvalStatus(true);
        });
    }

    const _isPendingWithMe = () => {
        let role = getCurrentRole();
        return role === 'TRADER' ? true : false
    }

    return (
        <div className='inviteContent'>
            <div className='inviteHeader'>
                <img src={APP_CONSTANTS.IMAGE_PATH + 'user.png'} />
                <div className='headerTitle ellipsis'>&nbsp;{user.name}</div>
            </div>
            <div className='inviteBody'>
                <div className='inviteMeta inline'>
                    {user.mobile && <div className='meta'><AddIcCallIcon color="action" fontSize="small" />&nbsp;&nbsp;Contact : <b><i>{user.mobile}</i></b></div>}
                    {user.email && <div className='meta'><AlternateEmailIcon color="action" fontSize="small" />&nbsp;&nbsp;Email : <b><i>{user.email}</i></b></div>}
                    {user.apedaRCMC && <div className='meta'><VerifiedUser color="action" fontSize="small" />&nbsp;&nbsp;APEDA RCMC : <b><i>{user.apedaRCMC}</i></b> </div>}
                    {user.address && <div className='meta location'><PinDropIcon color="action" fontSize="small" />&nbsp;&nbsp;{user.address}</div>}
                </div>
            </div>
            {
                user?.status || props.approvalStatus && <div className='inviteFooter'>{
                    getCurrentRole() === 'PLATFORM_ADMIN' ?
                        <div className='primaryBtn' onClick={(e) => _approveAdmin(e, user.id)}>Approve</div>
                        :
                        <>
                            {_isPendingWithMe() === true ?
                                <div className='primaryBtn' onClick={(e) => _approve(e, user.id)}>Approve</div>
                                :
                                <div className='statusDesc'>Approval Pending</div>
                            }
                        </>

                }
                </div>
            }

        </div>
    )
}