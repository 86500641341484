import { Autocomplete, TextField, styled, useAutocomplete } from '@mui/material';
import React from 'react'

const SearchDropdown = (props: any) => {
    const options: any[] = props.options[0]

    const {value, onChange} = props

    const [inputValue, setInputValue] = React.useState<string>("");

    const handleSelect = (newValue: any) => {
        let v = newValue ? newValue.id.toString() : null;
        onChange(v)
    }

    return (
        <div style={{ marginBottom: 5 }}>

            <Autocomplete options={options}
                getOptionLabel={(option: any) => option.name}
                onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
                onChange={(_, newValue) =>
                    handleSelect(newValue)
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label}
                        variant="outlined"
                    />
                )}
            />


        </div>
    )
}

export default SearchDropdown
