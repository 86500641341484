import { forkJoin } from "rxjs";
import { _get, _post, _put } from "./API";
import { DmsAPI } from "./DmsAPI";
import { UserMngmntAPI } from "./UserMgmntAPI";
import { TRADE_MGMT_API } from "./TradeManagementAPI";


export const MasterAPI = {

  getAllConsignments: async () => {
    return await _get(`/data/consignments.json`);
  },

  getAllHarvestCollections: async () => {
    return await _get(`/data/harvestCollections.json`);
  },
  getStaticFarms: async () => {
    return await _get(`/data/farms.json`);
  },
  getFarms: async () => {
    return await _get(`/data/mangoFarms.json`);
  },
  getConsignmentStepsById: async (id: string) => {
    let steps = await _get(`/data/consignmentSteps.json`);
    let stepsByConsignment = steps?.data?.find((s: any) => s.consignmentId == id);
    if (!stepsByConsignment && steps?.data?.length) {
      stepsByConsignment = steps.data[0];
    }
    return stepsByConsignment;
  },
  // uploadFiles: async (files: any) => {
  //   let obs: any = [];
  //   files.forEach((file: any) => {
  //     for (const f of file.files) {
  //       obs.push(
  //         DmsAPI.fileUpload(f).then(res => {
  //           return { comments: "", attachment: { code: res.body[0].documentId, doctype: file.type, meta: { date: file.date } } };
  //         })
  //       );
  //     }
  //   })
  //   return Promise.all(obs);
  // },

  getFarmers: async () => {
    return await _get(`/data/farmers.json`);
  },

  getPop: async () => {
    return await _get(`/data/pop.json`);
  },

  getNutritional: async () => {
    return await _get(`/data/nutritional.json`);
  },

  getTermsContent: async () => {
    return await _get(`/data/termsAndOthers.json`);
  },

  _fetchUsers: async (usertype: 'EXPORTER' | 'TRADER') => {

    UserMngmntAPI.searchBasedUser(usertype).then((res: any) => {
      return res;
    })
  },

  getContractUISchema: async (role: string) => {

    let users = UserMngmntAPI.searchBasedUser(role).then((res: any) => res)
    let varieties = TRADE_MGMT_API.getItemVarieties().then((res: any) => res)
    return await Promise.all([
      users, varieties
    ])

  }
}