import { useEffect, useState } from 'react';
import { IMAGES, APP_CONSTANTS, ROUTES, ROLES, getCurrentRole, PATHS_CONSTANT, getLoggedUserId, getLoggedUserName } from '../../../configs/Utils';
import './SideNav.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { appLogout } from '../../services/API';
import { UserMngmntAPI } from '../../services';
import { EntityDetails } from '../../../modules/EntityManagementModule/components';
import Wallet from '../wallet/wallet';
import { useDrawer } from '../../contexts/drawerContext';
import { pendingConsignmentsCountHandler } from '../../services/helper.services';

// import { useEvent } from '../../components/eventProvider';


const SideNav = (props: { _triggerBack?: any }) => {
    const { openDrawer, closeDrawer } = useDrawer();
    const navigate = useNavigate();
    const [menu, setMenu] = useState<any>([]);
    const location = useLocation();
    // const { setEvent } = useEvent();
    const [selectedMenu, setSelectedMenu] = useState<string>('')
    const [userDetails, setUserDetails] = useState<any>(null);
    const [pendingConsignmentsCount, setPendingConsignmentsCount] = useState<number>(0);

    const onMenuClick = (path: string) => {
        let menuToHighlight = menu.find((item: any) => path.includes(item.path));
        if (menuToHighlight) setSelectedMenu(menuToHighlight.path);
        props._triggerBack(false);
        navigate(path);
    }
    const onUserClick = (flag: boolean | null) => {
        if (flag) {
            UserMngmntAPI.getUserDetails(getLoggedUserId() as string).then((res) => {
                if (res) {
                    setUserDetails(res);
                    openDrawer(<EntityDetails data={res} isLoggedUser={true} entity={ROLES[getCurrentRole() as string]} profile={true} nav={()=>{closeDrawer(); navigate('../../generatePassword')}}/>);
                    return;
                }
            })
        }
        closeDrawer();
    }
    const logout = () => {
        appLogout();
    }

    useEffect(() => {
        let currentPath = location.pathname.replace(`/${PATHS_CONSTANT.POST_LOGIN}/`, '');
        if (menu.length > 0) {
            currentPath ? onMenuClick(currentPath) : onMenuClick(menu[0].path);
        }
    }, [menu]);

    useEffect(() => {
        let role = getCurrentRole();
        if (role) setMenu(ROUTES[role]);
        pendingConsignmentsCountHandler.subscribe((count: any) => {
            if (count) setPendingConsignmentsCount(count);
        });
    }, []);


    return (
        <div className="sidenavContainer relative">


            <div className="navCancel absolute" onClick={() => props._triggerBack(false)} >
                <img src={APP_CONSTANTS.IMAGE_PATH + 'cancel.png'} alt="cancel" />
            </div>


            <div className='navUpperSection'>
                <div className="sidenavHeader">
                    <div className='logoWrapper'>
                        <img className="sidenavLogo" src={APP_CONSTANTS.APP_LOGO} alt="logo" onClick={() => navigate(`/${PATHS_CONSTANT.POST_LOGIN}/${menu[0].path}`)} />
                        {/* <div className='appname'>{APP_CONSTANTS.APP_NAME}</div> */}
                    </div>
                </div>

                <div className="sidenavMenu">
                    {
                        menu.map((route: any, index: number) => (
                            <div key={index} className={"menuItem " + (selectedMenu === route.path ? 'selectedMenuItem' : '')} onClick={() => onMenuClick(route.path)}>
                                <div className='flex'>
                                    <div className="menuItemIcon">
                                        <img src={APP_CONSTANTS.IMAGE_PATH + (selectedMenu === route.path ? 'active-' : '') + IMAGES[route.icon]} alt={route.label} />
                                    </div>
                                    <div className="menuItemText">
                                        {route.label}
                                    </div>
                                </div>
                                <div className='flex'>
                                    {route.path === 'notifications' && pendingConsignmentsCount > 0 && <div className='menuItemCount'>
                                        {pendingConsignmentsCount}
                                    </div>}
                                </div>
                            </div>
                        ))
                    }

                </div>

            </div>

            <div className='flex'>
                <div className="sidenavMenu">

                    <span className='disabledItem'><Wallet minview={true} /></span>

                    <div className="menuItem usermenu" onClick={() => onUserClick(true)}>
                        <div className="menuItemIcon">
                            <img src={APP_CONSTANTS.IMAGE_PATH + 'user.png'} alt={'user'} />
                        </div>
                        <div className="menuItemText flexColumn">
                            <div className='username ellipsis'>{getLoggedUserName()}</div>
                            <div className='userrole'>{ROLES[getCurrentRole() as string]}</div>
                        </div>

                    </div>

                    <div className='flex btnGroup'>
                        <div className="logout" onClick={logout}>
                            Logout
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default SideNav;