import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, IconButton, List, ListItem, ListItemText, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'
import './DataViewComponent.scss'
import PublicIcon from '@mui/icons-material/Public';
import VariantAccordion from './VariantAccordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { typographyHeader, typographyData, dividerStyle, typographySubHeader } from './styles';
import Close from '@mui/icons-material/Close';

const DataViewComponent = (props: { code: any, onDismiss: any, onClick?: (batch: any) => void, batches?: [], onSelect?: any }) => {
  let profile = props.code;
  const [open, setopen] = useState<boolean>(false)
  console.log('pro', profile);
  let url = 'https://www.maya.explorer.borlaug.network/identity/did:bws:3a2e9ef9080e6a997ca15cf9f74cfb4c584a962cdd53c0a597ef22dd5e74f4dd'

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className='regionFormWrapper'>
      <Card variant="elevation" style={{ background: 'AppWorkspace', fillOpacity: 3 }}>
        <CardHeader
          avatar={
            <PublicIcon color={'action'} />
          }
          title="INDIA"
          // titleTypographyProps={typographyHeader}
          action={
            <IconButton onClick={() => props.onDismiss()}>
              <Close />
            </IconButton>
          }
        />
        <Divider style={{ borderWidth: 3, borderColor: 'grey', }} variant='middle' />
        <CardContent>

          <Grid container spacing={1}>
            <Grid item xs={4} >
              <Typography style={typographyHeader}>
                {`ORCHARD ID`}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={typographyData}>
                {` ${profile?.farmId}`}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={dividerStyle} />

          <Paper style={{ maxHeight: 350, overflow: 'auto' }}>
            <List style={{ padding: 5, }}>
              <Grid container spacing={4}>
                <Grid item spacing={4}>
                  <Typography style={typographySubHeader}>
                    TRESS
                  </Typography>
                  <Typography style={typographyData}>
                    {`${profile?.totalPlantedTrees}`}
                  </Typography>
                </Grid>

                <Grid item spacing={4}>
                  <Typography style={typographySubHeader} >
                    AREA
                  </Typography>
                  <Typography style={typographyData}>
                    {`${profile?.area?.amount} ${profile?.area?.unit}`}
                  </Typography>
                </Grid>

                {/* <Grid item spacing={2}>
                  <Typography style={typographySubHeader} >
                    EY (in Kg)
                  </Typography>
                  <Typography style={typographyData}>
                    {`${profile.ey}`}
                  </Typography>
                </Grid> */}
              </Grid>
              {/* <Divider style={dividerStyle} /> */}

              {/* <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Typography style={typographySubHeader} >
                    VARIANT
                  </Typography>
                </Grid>
              </Grid> */}
              {/* <VariantAccordion variant={profile.cultivatedItems} /> */}

              {/* <Divider style={dividerStyle} />

             
              <Button size="small" variant='text' style={{ color: '#FF8C00' }} onClick={() => setopen(true)}>
                More Info...
              </Button> */}

            </List>
          </Paper>

          {/* <Divider style={dividerStyle} />
          <Grid container>
            <Grid item xs={4}>
              <Typography style={typographySubHeader} >
                {`ADDRESS`}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={typographyData}>
                {` ${props.code.name}`}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={dividerStyle} />
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography style={typographySubHeader} >BATCHES</Typography>
                </AccordionSummary>
                {
                  props.batches?.map((batch: any) => {
                    return <AccordionDetails>
                      <Typography style={typographyData} onClick={() => props.onClick(batch)} className={'batch ' + (batch.id == props.onSelect?.id ? 'selected' : '')}>
                        {batch.name}
                      </Typography>

                    </AccordionDetails>
                  })}
              </Accordion>  */}


        </CardContent>
        <Divider style={{ borderWidth: 2, borderColor: 'grey', marginBottom: 10 }} variant='middle' />
        <CardActions style={{ justifyContent: 'center' }}>
          <Button size="small" variant='outlined' style={{ borderColor: '#FF8C00', color: '#FF8C00' }} onClick={() => openInNewTab(url)}>
            View Info on Blockchain
          </Button>
        </CardActions>
      </Card>
      {/* {open && <ModalView open={open} handleClose={() => { setopen(false) }} claims={profile.claims} />} */}
    </div>
  )
}

export default DataViewComponent
