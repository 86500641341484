import { SyntheticEvent } from 'react';
import { APP_CONSTANTS, formatDate, getCurrentRole, getLoggedUserId, getLoggedUserName } from '../../../../configs/Utils';
import { STATUS_DETAILS } from '../../../../configs/Workflow';
import ApprovalWidget from '../approvalWidget/approvalWidget';
import './consignmentCard.scss';

const ConsignmentCard = (props: {data: any, readonly?: boolean, showApprovalState?: boolean, emitApprovalStatus?: any}) => {
    const consignment = props.data;

    const _onClickQR = (id: any, e: SyntheticEvent) => {
        window.location.href = '/qr/' + id;
        e.stopPropagation();
    }
    const _onApprovalStatus = (isApproved: boolean) => {
        if (isApproved) {
            props.emitApprovalStatus(true);
        }
    }

    return (
        <div className='consignmentCard'>
            <div className='cardHeader'>
                <div className='cardTitle ellipsis'>#{consignment.id}</div>
                { !props.readonly && <div className='flex'><img src={APP_CONSTANTS.IMAGE_PATH + 'qr.png'} alt='QR' onClick={(e) => _onClickQR(consignment.id, e)}/></div> }
            </div>
            <div className='cardBody'>
                <div className='cardMeta inline'>
                    <div className='exporter'>{consignment.item.itemType} ({consignment.item.itemName})</div>
                    <div className='dateGroup'>
                        <div className='dateMeta'>Expected Delivery
                            <div className='date'>
                                {consignment.targetFulfillmentDate ? formatDate(consignment.targetFulfillmentDate) : 'Not updated'}
                            </div>
                        </div>
                    </div>
                    { consignment.status && consignment.status!='CREATED' && consignment.status!='COMPLETED' && <div className='dateGroup'>
                        <div className='dateMeta'><div className='date stage'>{STATUS_DETAILS[consignment.status].status}</div></div>
                    </div> }
                </div>
                <div className='quantityGroup inline'>
                    <div className='meta'>Quantity</div>
                    <div className='count'>{consignment.orderQty.amount} <span>{consignment.orderQty.unit}</span></div>
                </div>
            </div>
            {
                props.showApprovalState && <div className='cardFooter'>
                    <ApprovalWidget consignment={consignment} onEmitStatus={_onApprovalStatus}/>
                </div>
            }
            
        </div>
    );
}

export default ConsignmentCard;