import * as React from 'react';
import { Transition } from 'react-transition-group';
import CloseIcon from '@mui/icons-material/Close';
import {Snackbar,SnackbarCloseReason}  from '@mui/base';
import './alertComponent.scss'
import { ACTION_CONSTANTS } from '../../../configs/Utils';
import { MessageService } from '../../services/helper.services';


const AlertComponent = (props:{message:string,type:string}) => {
  const [open, setOpen] = React.useState(false);
  const [exited, setExited] = React.useState(true);
  const nodeRef = React.useRef(null);

  const close = () =>{
    setOpen(false);
    MessageService.handleUserStatus(false);

  }
  const handleClose = (_: any, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
    close();
      return;
    }
    close();
  };

  const handleOnEnter = () => {
    setExited(false);
  };

  const handleOnExited = () => {
    setExited(true);
  };
  React.useEffect(() => {
    setOpen(true)
  }, [props])

  return (
    <>
      <Snackbar className={'styledSnackbar '}
        autoHideDuration={3000}
        open={open}
        onClose={handleClose}
        exited={exited}
      >
        <Transition
          timeout={{ enter: 400, exit: 400 }}
          in={open}
          appear
          unmountOnExit
          onEnter={handleOnEnter}
          onExited={handleOnExited}
          nodeRef={nodeRef}
        >
          {(status) => (
            <div className={'snackbarContent ' + (props.type == ACTION_CONSTANTS.ERROR ? ' errorDialog': props.type == ACTION_CONSTANTS.WARNING ? ' warningDialog' : props.type == ACTION_CONSTANTS.SUCCESS ? ' successDialog':'')}
              style={{
                transform: positioningStyles[status],
                transition: 'transform 300ms ease',
              }}
              ref={nodeRef}
            >
              <div className="snackbar-message">
                <p className="snackbar-title">{props.type}</p>
                <p className="snackbar-description">
                  {props.message}
                </p>
              </div>
              <CloseIcon onClick={() => close()} className="snackbar-close-icon" />
            </div>
          )}
        </Transition>
      </Snackbar>
    </>
  );
}

export default AlertComponent;

const positioningStyles = {
  entering: 'translateX(0)',
  entered: 'translateX(0)',
  exiting: 'translateX(500px)',
  exited: 'translateX(500px)',
  unmounted: 'translateX(500px)',
};