import { _get, _post, _put } from "./API";

const baseURL = process.env.REACT_APP_BASE_URL;
const subpath = 'trade-mgmt';

const contentTypeMap: any = {
    'pdf': 'application/pdf',
    'png': 'image/png',
    'jpeg': 'image/jpeg',
    'jpg': 'image/jpeg',
    'jfif': 'image/jpeg',
    'gif': 'image/gif',
    'csv': 'text/csv'
}

const convertUrlToFile = async (dataURI: string, index: any) => {
    return await fetch(dataURI).then(response => response.blob()).then(blob => {
        const file = new File([blob], 'file' + index, {type: blob.type})
        return file;
    })
}

export const DmsAPI = {
    // fileUpload: async (file: any) => {
    //     var formData = new FormData();
    //         formData.append('files', file);
    //     // }
    //     return await _post(`${baseURL}/fileupload`, formData);
    // },
    fetchAuthenticatedFile: async (code: string, ext?: string) => {
        const res = await _get(DmsAPI.getDocUrl(code), { responseType: 'blob' });
        const blob = await new Blob([res], { type: contentTypeMap[ext?.replace('.', '') as string] });
        return URL.createObjectURL(blob);
    },
    downloadDocUrl: async (data: any) => {
        return await _get(DmsAPI.getDocUrl(data));
    },

    getDocUrl: (data: any) => {
        return `${baseURL}/${subpath}/dms/download/${data}`;
    },

    submitDocumentation: async (data: any) => {
        return await _post(`${baseURL}/${subpath}/dms/fileupload`, data);
    },
    submitPublicDocumentation: async (data: any) => {
        return await _post(`${baseURL}/${subpath}/dms/public/fileupload`, data);
    },
}