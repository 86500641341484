import React, { useEffect, useState } from 'react'
import { APP_CONSTANTS } from '../../../configs/Utils'
import './qRView.scss'
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, CardMedia, useMediaQuery } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { qrStagesMap } from '../../../configs/Workflow'
import { useParams } from 'react-router-dom';
import { TRADE_MGMT_API } from '../../services';
import { CollectionUnits, ConsignmentCard } from '../../../modules/TradeManagementModule/widgets';
import { SubmittedData } from '../../../modules/TradeManagementModule/components';
import { AttachmentsSummary } from '../../widgets';

const QRView = () => {
    const isDesktopOrLaptop = useMediaQuery('(min-width: 1224px)')
    const isBigScreen = useMediaQuery('(min-width: 1824px)')
    const isTabletOrMobile = useMediaQuery('(max-width: 1224px)')
    const isPortrait = useMediaQuery('(orientation: portrait)')
    const isRetina = useMediaQuery('(min-resolution: 2dppx)')
    const { id } = useParams();
    const [stages, setStages] = React.useState<any>(null);
    const [expandedStage, setExpanded] = React.useState<any>();

    // let url = 'https://www.maya.explorer.borlaug.network/provenance/1329/'

    const openInNewTab = (url: string): void => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    const handleChange = (panel: string) => {
        expandedStage === panel ? setExpanded(null) : setExpanded(panel);
    };

    const _getQRData = (id: any) => {
        TRADE_MGMT_API.getQRData(id).then((res: any) => {
            res = {'Consignment Images': {images: [1, 3, 5]}, ...res};
            setStages(res);
            handleChange(Object.keys(res)[0]);
        })
    }

    useEffect(() => {
        _getQRData(id);
    }, [id])



    return (
        <div className='qrContainer'>

            <div className='headerWrap'>
                <div className='heading'>
                    <div className='logowrapper'>
                        <img className="logo" src={APP_CONSTANTS.APP_LOGO} alt="logo" />
                    </div>

                </div>
                <div className="introWrap">
                    <div className='intro'>
                        The Mango Chronicles:<br /><span>   Farm to Trade and everything in between</span>
                    </div>

                </div>
            </div>

            {
                stages &&
                <div className='qrBodyWrap'>
                    <div className='consignmentWrap inline'>
                        {
                            stages?.consignmentCreation &&
                            <div className='flexColumn'>
                                <DetailsCard data={stages.consignmentCreation} />
                                <ExporterCard data={stages.consignmentCreation.exporter} />
                                <div className="primaryBtn" onClick={() => openInNewTab(stages.consignmentCreation.blockchain)}>
                                    View On Blockchain
                                </div>
                            </div>
                        }

                    </div>

                    <div className='processCardWrap inline'>
                        {
                            stages &&
                            Object.keys(stages).map((stage: any, i: number) => (
                                <Accordion key={i} expanded={expandedStage === stage} onChange={() => handleChange(stage)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id={stage + "-header"}>
                                        <div className="processHeader"><div className='processTitle'>{qrStagesMap[stage] ? qrStagesMap[stage] : stage}</div></div>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <ContentCard content={stages[stage]} stage={stage}/>
                                    </AccordionDetails>
                                </Accordion>
                            ))
                        }
                    </div>
                </div>
            }
            {
                !stages &&
                <div className='noDataWrap'>
                    <div className='noData'>Record not updated!</div>
                    <div className='noDataDesc'>Please check back later</div>
                </div>
            }
        </div>
    )
}

export default QRView

const ContentCard = (props: { content: any, stage: string }) => {
    const { id } = useParams();
    const collections = props.content.farmCollections;
    const attachments = props.content.attachments;
    const formDetails = props.content.formDetails;
    const images = props.content.images;
    const [consignment, setConsignment] = useState<any>();

    const _mapConsignmentDetails = (data: any) => {
        let con: any = {};
        con.id = id;
        con.item = data.item;
        con.orderQty = data.consignmentQuantity;
        con.targetFulfillmentDate = data.targetFulfillmentDate;
        setConsignment(con);
    }

    useEffect(() => {
        if (props.content?.item) _mapConsignmentDetails(props.content);
    }, [props.content])

    return (
        <div className='contentCard'>
            {consignment &&
                <div className='consignmentDetails'><ConsignmentCard data={consignment} readonly={true} /></div>
            }
            {collections &&
                <div className='processContent'>
                    <div className='processContentHeader'>Harvest Collections</div>
                    <CollectionUnits collections={collections} />
                </div>
            }
            {attachments && attachments.length > 0 &&
                <div className='processContent'>
                    <div className='processContentHeader'>Attachments</div>
                    <div className='processContentBody'>
                        <AttachmentsSummary attachments={attachments} />
                    </div>
                </div>
            }
            {formDetails &&
                <div className='processContent'>
                    <div className='processContentHeader'>Additional Details</div>
                    <div className='processContentBody'>
                        <SubmittedData data={formDetails} />
                    </div>
                </div>

            }
            {
                images && images.length > 0 && props.stage === 'Consignment Images' &&
                <div className='processContent'>
                    <MangoImages images={images} />
                </div>
            }
        </div>
    )
}


const MangoImages = ({images}: any) => {
    return (
        <div className='introImages'>
            {images.map((key: any) => (
                <div key={key} className='image relative'>
                    <img src={APP_CONSTANTS.IMAGE_PATH + `mangoes/${key}.jpg`} alt="mango" />
                </div>
            ))}
        </div>
    )
}


const DetailsCard = (props: { data: any }) => {
    return (
        <>
            <h5>{props.data.item.itemType}</h5>
            <h6>{props.data.item.itemName}</h6>
            <div className='seperator'></div>
            <p className='desc'>
                {props.data.item.nutritionalInfo}
            </p>
        </>
    )
}

const ExporterCard = (props: { data: any }) => {
    return (
        <div className='exporterCard'>
            <div>Exporter</div>
            <h6>{props.data.name}</h6>
            <hr />
            <p className='profileinfo'> Contact : <b>{props.data.mobile}</b> </p>
            <p className='profileinfo'> Address : <b>{props.data.address}</b> </p>
        </div>
    )
}