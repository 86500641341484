import { APP_CONSTANTS } from '../../../configs/Utils';
import { useDrawer } from '../../contexts/drawerContext';
import './wallet.scss';

const Wallet = (props: {minview?: boolean}) => {

    const { openDrawer, closeDrawer } = useDrawer();

    const _onSummaryClick = (type: 'openwallet' | 'balance' | 'usedamount') => {
        switch(type) {
            case 'openwallet':
                _openWallet();
                break;
        }
    }

    const _openWallet = () => {
        openDrawer(<Wallet />);
    }
    return (
        <div className={'walletContainer ' + (props.minview ? '' : 'maxHeight') }>
            {
                props.minview && 
                    <AmountSummary title='Wallet Balance' amount='295' type='openwallet' onSummaryClick={_onSummaryClick}/>
            }
            {
                !props.minview && 
                <div className='walletBody'>
                    <div className='flexColumn justify-content-between maxHeight'>
                        <div className='flexColumn'>
                            <AmountSummary title='Available Balance' amount='295' type='balance' />
                            <br/>
                            <AmountSummary title='Used Amount' amount='135' type='usedamount'/>
                            <br/>
                            <History/>
                        </div>
                        <div className='flexColumn'>
                            <div className='primaryBtn maxWidth'>Add Money</div>
                            <div className='disclaimer'>* Commission charged against consignments : <b>&#8377; 25 per Tonne</b></div>
                        </div>
                    </div>
                </div>
            }
            
        </div>
    )
}

export default Wallet;

const AmountSummary = (props: {amount: any, title: any, type:'openwallet' | 'balance' | 'usedamount', onSummaryClick?: any}) => {
    return (
        <div className={'balanceSummary ' + (props.onSummaryClick ? 'clickable' : '')} onClick={() =>  props.onSummaryClick ? props.onSummaryClick(props.type) : {}}>
            <div className='flexColumn'>
                <div className='meta'>{props.title}</div>
                <div className={'value ' + (props.type === 'usedamount' ? 'usedValue' : '')}>&#8377; {props.amount}</div>
            </div>
            { props.onSummaryClick && <img src={APP_CONSTANTS.IMAGE_PATH + 'back.png'} alt="wallet" /> }
        </div>
    )
}

const History = () => {
    const items = [
        { consignment: '#65365442347', amount: 100, date: '12/12/2021', type: 'credit' },
        { consignment: '#67464763776', amount: 63, date: '12/12/2021', type: 'credit' },
        { consignment: '#12234y27676', amount: 77, date: '12/12/2021', type: 'credit' },
        { consignment: '#75454644645', amount: 80, date: '12/12/2021', type: 'debit' },
        { consignment: '#45343535545', amount: 38, date: '12/12/2021', type: 'credit' },
        { consignment: '#97978978987', amount: 267, date: '12/12/2021', type: 'credit' },
        { consignment: '#26346656466', amount: 200, date: '12/12/2021', type: 'credit' },
        { consignment: '#36565456776', amount: 100, date: '12/12/2021', type: 'debit' }
    ]
    return (
        <div className='historyContainer'>
            <div className='historyTitle'>Transaction History</div>
            <div className='historyBody'>
                {
                    items.map((item: any, index: number) => (
                        <div key={index} className='historyItem flex justify-content-between'>
                            <div className='flexColumn itemName'>
                                <h6>{item.consignment}</h6>
                                <div className='meta'>{item.date}</div>
                            </div>
                            
                            <div className={'itemAmount ' + (item.type === 'credit' ? 'credit' : 'debit')}>{item.type === 'credit' ? '' : '-'} &#8377; {item.amount}</div>
                        </div>
                    ))
                }
                
            </div>
        </div>
    )
}