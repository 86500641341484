import { _get, _patch, _post, _put } from "./API";

const baseURL = process.env.REACT_APP_BASE_URL;
const subpath = 'trade-mgmt';
const fetchedDataSet: any = {};

export const TRADE_MGMT_API = {
  createTradeContract: async (data: any) => {
    return await _post(`${baseURL}/${subpath}/trade`, data);
  },
  getNewContracts: async () => {
    return await _get(`${baseURL}/${subpath}/trade/new`);
  },
  getActiveContracts: async () => {
    return await _get(`${baseURL}/${subpath}/trade/active`);
  },
  getCompletedContracts: async () => {
    return await _get(`${baseURL}/${subpath}/trade/completed`);
  },
  getPendingContracts: async () => {
    return await _get(`${baseURL}/${subpath}/trade/approval-pending`);
  },
  getContractById: async (id: string) => {
    return await _get(`${baseURL}/${subpath}/trade/${id}`);
  },
  allocateFarmsAndOfficers: async (data: any) => {
    return await _put(`${baseURL}/${subpath}/trade/${data.id}/allocate`, data);
  },
  addHarvestCollection: async (data: any, cid: any) => {
    return await _put(`${baseURL}/${subpath}/trade/${cid}/collection`, data);
  },
  getStageDocuments: async (conId: string) => {
    return await _get(`${baseURL}/${subpath}/trade/alltreatments`);
  },
  // submitDocumentation: async (data: any, cid: any) => {
  //   return await _put(`${baseURL}/${subpath}/trade/${cid}/documents`, data);
  // },
  
  submitStage: async (cid: any, method?: any, payload?: any) => {
    payload = payload ? payload : {};
    method = method ? method : 'submit';
    return await _put(`${baseURL}/${subpath}/trade/${cid}/${method}`, payload);
  },
  performPrepackhouseShipment: async (data: any, cid: any) => {
    return await _post(`${baseURL}/${subpath}/trade/${cid}/batches`, data);
  },
  getQRData: async (cid: any) => {
    return await _get(`${baseURL}/${subpath}/trade/${cid}/qr`);
  },
  getTradeTransition: async (cid:any)=>{
    return await _get(`${baseURL}/${subpath}/trade/${cid}/transitions`);
  },
  approveConsignment: async (body: any) => {
    return await _patch(`${baseURL}/${subpath}/trade/approve`, body);
  },
  getItemVarieties: async () => {
    return await _get(`${baseURL}/${subpath}/trade/horti-items/varieties`);
  },
}