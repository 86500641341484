import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";

export const Button = styled(MuiButton)(({ onClick }) => ({
    flex: 1,
    color: 'black',
    fontStyle: 'italic',
    backgroundColor: 'lightblue',
    width: '80%',
    marginTop: 5,
}));


export const sxBox = {
    width: '50%',
    // border: 'dashed 2px lightblue',
    padding: 10,
    marginTop: 5,
    alignItem: 'center',
};



export const styleTF = {
    width: '50%',
    padding: 10,
    marginVerticle: 5,
}


export const styleContainer = {
    fixed: true,
    marginTop: 50,
    align: 'center',
}


export const typographyHeader = {
    fontSize: 13,
    fontWeight: '500',
    color: '#FF8C00',
}

export const typographySubHeader = {
    fontSize: 13,
    fontWeight: '400',
    color: '#a9a9a9'
}

export const typographyData = {
    fontSize: 16,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // fontWeight: '400',
}

export const dividerStyle = { marginBottom: 10, marginTop: 10, borderColor: 'grey', }