import dayjs from "dayjs"
import { DATA_MGMT_API, MessageService } from "../shared/services"
import { consignmentDocumentsHandler, schemaHandler } from "../shared/services/helper.services"

export const APP_CONSTANTS: any = {
  APP_NAME: 'Mango Traceability',
  APP_LOGO: '/assets/images/logo.jpg',
  IMAGE_PATH: '/assets/images/',
  PDF_PATH: '/assets/pdf/',
  USERID: 'userid',
  USERNAME: 'username',
  ROLE: 'role',
  TOKEN: 'token',
  REFRESH_TOKEN: 'refreshtoken'
}

export const ROLES: any = {
  PLATFORM_ADMIN: 'ADMIN',
  FIELD_OFFICER: 'FIELD_OFFICER',
  TRADER: 'TRADER',
  EXPORTER: 'EXPORTER',
}

export const CONSIGNMENT_STATUS: any = {
  NEW: 'Upcoming',
  ACTIVE: 'Active',
  COMPLETED: 'Completed'
}

export const INVITES_STATUS: any = {
  ACTIVE: 'Pending',
  COMPLETED: 'Approved'
}

export const IMAGES: any = {
  'LOGOUT': 'logout.png',
  'TRADERS': 'traders.png',
  'EXPORTERS': 'exporters.png',
  'DASHBOARD': 'dashboard.png',
  'NOTIFICATIONS': 'notification.png',
  'FARMS': 'farm.png',
  'FARMERS': 'traders.png',
  'FIELD_OFFICER': 'officer.png',
}

export const ROUTES: any = {
  'TRADER': [
    { label: 'Dashboard', id: 1, path: 'consignments', icon: 'DASHBOARD' },
    { label: 'Field Officers', id: 5, path: 'fieldOfficers', icon: 'FIELD_OFFICER' },
    { label: 'Exporters', id: 3, path: 'exporters', icon: 'EXPORTERS' },
    { label: 'Farms', id: 4, path: 'farms', icon: 'FARMS' },
    { label: 'Notifications', id: 6, path: 'notifications', icon: 'NOTIFICATIONS' },
  ],
  'FIELD_OFFICER': [
    { label: 'Dashboard', id: 1, path: 'consignments', icon: 'DASHBOARD' },
    { label: 'Farms', id: 4, path: 'farms', icon: 'FARMS' },
    { label: 'Notifications', id: 6, path: 'notifications', icon: 'NOTIFICATIONS' }
  ],
  'EXPORTER': [
    { label: 'Dashboard', id: 1, path: 'consignments', icon: 'DASHBOARD' },
    { label: 'Traders', id: 2, path: 'traders', icon: 'TRADERS' },
    { label: 'Notifications', id: 6, path: 'notifications', icon: 'NOTIFICATIONS' }
  ],
  'PLATFORM_ADMIN': [
    { label: 'Dashboard', id: 1, path: 'consignments', icon: 'DASHBOARD' },
    { label: 'Traders', id: 2, path: 'traders', icon: 'TRADERS' },
    { label: 'Field Officers', id: 5, path: 'fieldOfficers', icon: 'FIELD_OFFICER' },
    { label: 'Exporters', id: 3, path: 'exporters', icon: 'EXPORTERS' },
    { label: 'Farms', id: 4, path: 'farms', icon: 'FARMS' },
    { label: 'Notifications', id: 6, path: 'notifications', icon: 'NOTIFICATIONS' }
  ],
}

export const EXTRA_BTN = [
  {
    id: 1, label: 'Details', icon: 'info.png'
  },
  {
    id: 2, label: 'Track', icon: 'location.png'
  }
]

export const PATHS_CONSTANT: any = {
  ADMIN: 'admin',
  TRADE_ADMIN: 'trader',
  EXPORTER: 'exporter',
  PROJECT_STAFF: 'field_officer',
  LOGIN: 'login',
  SIGNUP: 'sign_up',
  POST_LOGIN: 'traceability',
  FARM: 'farms',
}

export const _logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem(ACTION_CONSTANTS.USER);
  localStorage.removeItem(ACTION_CONSTANTS.USERID);
  localStorage.setItem('userType', 'user');
  localStorage.removeItem('refreshtoken');
  localStorage.removeItem(ACTION_CONSTANTS.ADMIN_UDIN);
  window.location.href = process.env.REACT_APP_BASEHREF as string;
}

export const ACTION_CONSTANTS: any = {
  'PLATFORM': 'platform',
  'USER': 'user',
  'ADMIN': 'admin',
  'CONFIRM': 'confirm',
  'CLOSE': 'close',
  'WARNING': 'warning',
  'ERROR': 'error',
  'SUCCESS': 'success',
  'USERID': 'userid',
}

export const MANGO_VARIANT = [[
  { name: 'Dasheri', id: 'Dasheri' },
  { name: 'Langra', id: 'Langra' },
  { name: 'Chaunsa', id: 'Chaunsa' },
  { name: 'Tukmi', id: 'Tukmi' },
  { name: 'Safeda', id: 'Safeda' },
  { name: 'Banganapalli', id: 'Banganapalli' },
]]

export const CROPS = [[
  { name: 'Mango', id: 'Mango' }
]]

export const MODE = [
  { const: 'air', title: 'Air' },
  { const: 'water', title: 'Water' },
]

export const PORT = [
  { name: 'Haldia', id: 'Haldia', title: 'Haldia', const: 'Haldia' },
  { name: 'Lucknow', id: 'Lucknow', title: 'Lucknow', const: 'Lucknow' },
]


export const EXPORTER = [
  {
    "id": "Q05BVFVWV1gxMjM0Uw==",
    "name": "Salman Khan"
  },
  {
    "id": "Q05BVFVWV1gxMjM0WQ==",
    "name": "Siddharth Malhotra"
  },
  {
    "id": "Q05BWkFCQ0QxMjM0WQ==",
    "name": "Debopriya Malhotra"
  },
  {
    "id": "Q05BR1VFTEg0NjU4Vg==",
    "name": "Mehek Srivastav"
  }
]

export const PACKHOUSE = [[
  { name: 'Innova Food Park, Bengaluru', id: 'Innova Food Park, Bengaluru' },
  { name: 'Mango Pack House, Rehmankhera, Lucknow', id: 'Mango Pack House, Rehmankhera, Lucknow' },
  { name: 'Kosuri Balaji Agro Foods Private Limited, Ayyanki Village (Andhra Pradesh)', id: 'Kosuri Balaji Agro Foods Private Limited, Ayyanki Village (Andhra Pradesh)' }
]]

export const METHODS_OF_HARVEST = [[
  { name: 'Hand picking', id: 'Hand picking' },
  { name: 'Pole picking', id: 'Pole picking' },
  { name: 'Harvest tool (like a hook or a sickle)', id: 'Harvest tool (like a hook or a sickle)' },
  { name: 'Mechanical harvest', id: 'Mechanical harvest' },
  { name: 'Other', id: 'Other' }
]]

export const country = [
  {
    "name": "India",
    "id": "IN"
  },
  {
    "name": "South Korea",
    "id": "ROK"
  },
  {
    "name": "Russia",
    "id": "RU"
  },
  {
    "name": "United States of America",
    "id": "USA"
  }
]


export const claimTabs = [
  { name: 'tillage', value: 'Tillage' },
  { name: 'pestManagement', value: "Pest Management", },
  { name: 'irrigation', value: "Irrigation", },
  { name: 'manureAndFertilizer', value: 'Manure and Fertilizer Management', },
  { name: 'pruning', value: 'Pruning' },
];

export const notificationTabs = [
  { name: 'consignment', value: 'Consignments' },
  { name: 'other', value: "Invites", },
]

export const adminNotificationTabs = [
  { name: 'pending', value: 'Pending Exporters' },
  { name: 'other', value: "Others", },
]

export const predefinedStages: any = {
  'TRADER': [
    { from: 'ALLOCATION', role: 'TRADER' },
    { from: 'COLLECTING', to: 'COLLECTION_COMPLETED', role: 'TRADER' }
  ],
  'FIELD_OFFICER': [
    { from: 'COLLECTING', to: 'COLLECTION_COMPLETED', role: 'FIELD_OFFICER' }
  ],
  'EXPORTER': [
    { from: 'COLLECTING', to: 'COLLECTION_COMPLETED', role: 'EXPORTER' }
  ],
  // 'PLATFORM_ADMIN': [
  //   { from: 'ALLOCATION', role: 'PLATFORM_ADMIN' },
  //   { from: 'COLLECTING', to: 'COLLECTION_COMPLETED', role: 'PLATFORM_ADMIN' }
  // ]
}



export const getCurrentRole = () => {
  return localStorage.getItem(APP_CONSTANTS.ROLE);
}
export const getLoggedUserId = () => {
  return localStorage.getItem(APP_CONSTANTS.USERID);
}
export const getLoggedUserName = () => {
  return localStorage.getItem(APP_CONSTANTS.USERNAME);
}

export const formatDate = (dt: any) => {
  if (dt) return dayjs(dt).format('DD-MM-YYYY');
  return '';
}
export const checkDatatype = (d: any) => {
  if (d) {
    return d.constructor === Array ? 'array'
      : d.constructor === Object ? 'object'
        : d.constructor === String ? 'string'
          : 'other';
  }
  return 'other';
}

export const getStageDetails = (stage: string) => {
  return new Promise(resolve => {
    consignmentDocumentsHandler.subscribe((stageObj: any) => {
      if (stageObj) {
        let form = stageObj.filter((ui: any) => ui.from === stage)[0];
        form ? resolve(form) : resolve(null);
      }

    })
  })
}
// export const getSchema = (stage: string) => {
//   return new Promise(resolve => {
//     consignmentDocumentsHandler.subscribe((stageObj: any) => {
//       if (stageObj) {
//         let form = stageObj.filter((ui: any) => ui.from === stage)[0];
//         if (form) resolve(form.form)
//       }

//     })
//   })
// }


export const getUISchema = () => {
  return new Promise(resolve => {
    DATA_MGMT_API.getUiSchema().then((response: any) => {
      MessageService.emitSchemaProperties(response);
      resolve(response);
    });
  })
}

export const fetchLocation = () => new Promise((resolve, reject) => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(function (position) {
      resolve({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
      reject('Geolocation not enabled')
    });
  } else {
    MessageService.setStatus({ type: 'Warning', text: "Geolocation is not available in your browser." })
    reject('Geolocation not enabled')
  }
})


export const extractStagesByRoles = (stateDocs: any) => {
  let role = getCurrentRole();
  return stateDocs.filter((state: any) => state.role === role);
}
// const columns: GridColDef[] = [
//   { field: 'id', headerName: 'ID', },
//   { field: 'name', headerName: 'Name', flex: 1, },
//   { field: 'mobile', headerName: 'Conatct No.', flex: 0.75, },
//   { field: 'email', headerName: 'Email', flex: 1, },
//   { field: 'address', headerName: 'Address', flex: 1, },
//   { field: 'apedaRCMC', headerName: 'APEDA Reg No.', flex: 1, },
//   {
//       field: 'actions',
//       type: 'actions',
//       headerName: 'Actions',
//       width: 100,
//       cellClassName: 'actions',
//       getActions: ({ id }) => {
//           return [
//               <GridActionsCellItem
//                   icon={<Visibility />}
//                   label="Details"
//                   className="textPrimary"
//                   onClick={() => moveToDetails(id)}
//                   color="inherit"
//               />,
//               <GridActionsCellItem
//                   icon={<MoreVert />}
//                   label="More"
//                   className="textPrimary"
//                   onClick={(event) => handleMoreClick(event, id)}
//                   color="inherit"
//               />,
//           ]
//       }
//   },
// ]