import { useEffect, useState } from 'react';
import { APP_CONSTANTS } from '../../../../configs/Utils';
import './farmCard.scss';

const FarmCard = (props: { farm: any, consignment: any, isSelected: boolean, isActive?: boolean, isAdvanced: boolean, onFarmSelect: any, pendingAllocations: any }) => {

  const [tempAllocations, setTempAllocations] = useState(0);

  const _getTempAllocations = () => {
    let tmp = props.pendingAllocations.find((e: any) => e.farmId === props.farm.farmId)?.targetQty?.amount;
    tmp = tmp ? tmp : 0;
    setTempAllocations(tmp);
  }
  const _onFarmClick = (e: any, flag?: 'remove') => {
    props.onFarmSelect(props.farm, flag);
    if (flag) e.stopPropagation();
  }
  useEffect(() => {
    _getTempAllocations();
  }, [props.pendingAllocations]);



  return (
    <div className={'farmcard ' + (props.isSelected ? 'selectedFarmCard' : props.isActive ? 'activeFarmCard' : '')} onClick={(e) => _onFarmClick(e)}>
      <div className='header'>

        <div className='farmData flexColumn ellipsis'>
          <h6><b>{props.farm.farmName}</b> <div className='meta ellipsis'>#{props.farm.farmId}</div></h6>
          
          <div className='meta'>Farmer : <b>{props.farm.farmerName}</b></div>
          <div className='meta location'><img src={APP_CONSTANTS.IMAGE_PATH+'location.png'} /> <b>[{`${props.farm.coordinates.lat}, ${props.farm.coordinates.lon}`}]</b></div>
        </div>


        <div className='flex'>
            {
              tempAllocations > 0 && <div className='tempAllocations flexColumn'>
                <div className='meta'>selected Qty</div>
                <div className='meta activeColor'><b>{tempAllocations}</b> {props.consignment.orderQty.unit}</div>
                <div className='secondaryBtn removeBtn' onClick={(e) => _onFarmClick(e, 'remove')}>Remove</div>
              </div>
            }

            <div className='treeData'>
              <div className='imgWrap'><img src={APP_CONSTANTS.IMAGE_PATH + 'tree.png'} alt='tree' /></div>
              <div className='metagroup flexColumn'>
                { props.farm.plantedTrees && <div className='meta'>Trees : <b>{props.farm.plantedTrees}</b> </div> }
                <div className='meta'>Variety : <b>{props.consignment.item.itemName}</b></div>
                <div className='meta'>Area : <b>{props.farm.area.amount}{" "}{props.farm.area.unit}</b></div>
              </div>
            </div>
        </div>
        
      </div>
    </div>
  );
}

export default FarmCard;