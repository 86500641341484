import { useEffect, useState } from 'react'
import { claimTabs } from '../../../../configs/Utils'
import { MasterAPI } from '../../../../shared/services/MasterAPI';
import ScrollableTabsHeader from '../../../../shared/components/scrollableTabsHeader/scrollableTabsHeader';
import './Practices.scss';

const Practices = () => {
  const [selected, setSelected] = useState(0);
  const [selectedTab, setSelectedTab] = useState<any>();
  const [tillage, settillage] = useState([]);
  const [pestMang, setpestMang] = useState([]);
  const [manureFertilisers, setmanureFertilisers] = useState([])
  const [irrigation, setirrigation] = useState([])
  const [pruning, setpruning] = useState([])


  const handleChange = (tab: any) => {
    let newValue = claimTabs.findIndex((e: any) => e.name === tab.name);
    setSelected(newValue);
    setSelectedTab(tab);
  };

  // function a11yProps(index: number) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     'aria-controls': `simple-tabpanel-${index}`,
  //   };
  // }
  // const StyledTab = styled(Tab)({
  //   color: '#67903C',
  //   fontFamily: 'Poppins',
  //   "&.Mui-selected": {
  //     color: "#365219",
  //   }
  // });

  useEffect(() => {
    MasterAPI.getPop().then(res => {
      let pop = res.data[0];
      setirrigation(pop.irrigation);
      setmanureFertilisers(pop.manureAndFertilizer);
      setpestMang(pop.pestManagement);
      setpruning(pop.pruning);
      settillage(pop.tillage);
    })
  }, [])

  const SelectionCard = () => {
    if (selected === 0)
      return <TillageCard data={tillage} />
    else if (selected === 1)
      return <PesticidesCard data={pestMang} />
    else if (selected === 2)
      return <IrrigationCard data={irrigation} />
    else if (selected === 3)
      return <ManureAndFertiliserCard data={manureFertilisers} />
    else if (selected === 4)
      return <PruningCard data={pruning} />
    else return <></>
  }

  return (
    <div className='maxHeight'>
      <ScrollableTabsHeader tabs={claimTabs} activeTab={selectedTab} onTabClick={handleChange} type='general' />
      <div className='selectionCardWrap'>
        <SelectionCard />
      </div>
    </div>
  )
}

export default Practices

const PruningCard = (props: { data: any }) => {
  return (<>
    {
      props.data.map((e: any, i: number) =>
        <div className={'popCardWrap'} key={i}>
          <div className='header'>
            <div className='farmData flexColumn'>
              <h6><b>{e.Month}</b></h6>

              <div className='meta'>Apply Copper-oxychloride paste  : <b>{e.Apply_Copper_oxychloride_paste}</b></div>
            </div>
            <div className='treeData'>
              <div className='metagroup flexColumn'>
                {e.Month && <div className='meta'>No oF labour  : <b>{e.No_oF_labour}</b> </div>}
                <div className='meta'>Labour cost : <b>{e.Labour_cost}</b></div>
                <div className='meta'>Total cost : <b>{e.Total_cost}</b></div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  </>)
}

const TillageCard = (props: any) => {

  return (
    <>
      {
        props.data.map((e: any, i: number) =>
          <div className={'popCardWrap'} key={i}>
            <div className='header'>
              <div className='farmData flexColumn'>
                <h6><b>{e.Month}</b></h6>
                <div className='meta'>Implement : <b>{e.Implement}</b></div>
                <div className='meta'>No of tillage : <b>{e.No_of_tillage}</b></div>
              </div>
              <div className='treeData'>
                <div className='metagroup flexColumn'>
                  {e.Month && <div className='meta'>Cost/Hour  : <b>{e.Cost_per_Hour}</b> </div>}
                  <div className='meta'>Duration/Hours : <b>{e.Duration_per_Hours}</b></div>
                  <div className='meta'>Total : <b>{e.Total}</b></div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>)
}

const ManureAndFertiliserCard = (props: any) => {
  return (<>
    {
      props.data.map((e: any, i: number) =>
        <div className={'popCardWrap'} key={i}>
          <div className='header'>
            <div className='farmData flexColumn'>
              <h6><b>{e.Month}</b></h6>

              <div className='meta'>No of Application : <b>{e.No_of_Application}</b></div>
              <div className='meta'>Manure and Fertilizer Name : <b>{e.Manure_and_Fertilizer_Name}</b></div>
            </div>
            <div className='treeData'>
              <div className='metagroup flexColumn'>
                <div className='meta'>Cost /kg/Trolly  : <b>{e.Cost_kg_Trolly}</b> </div>
                <div className='meta'>Quantity/Kg/Trolly : <b>{e.Quantity_Kg_Trolly}</b></div>
                <div className='meta'>M & F Cost : <b>{e.M_F_Cost}</b></div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  </>)
}


const PesticidesCard = (props: any) => {
  return (<>
    {
      props.data.map((e: any, i: number) =>
        <div className={'popCardWrap'} key={i}>
          <div className='header'>
            <div className='farmData flexColumn'>
              <h6><b>{e.Time_of_Spray}</b></h6>

              <div className='meta'>No. of Spray  : <b>{e.No_of_Spray}</b></div>
              <div className='meta'>Name of chemical  : <b>{e.Name_of_chamical}</b></div>
            </div>
            <div className='treeData'>
              <div className='metagroup flexColumn'>
                {e.Time_of_Spray && <div className='meta'>Chamical price/liter/kg : <b>{e.Chamical_price_liter_kg}</b> </div>}
                <div className='meta'>Quantity/Tanker/liter/Kg : <b>{e.Quantity_Tanker_liter_Kg}</b></div>
                <div className='meta'>No of tanker : <b>{e.No_of_tanker}</b></div>
                <div className='meta'>Total Chemical/ Litre/Kg : <b>{e.Total_Chemical_Litre_Kg}</b></div>
                <div className='meta'>Chemical Cost : <b>{e.Chemical_Cost}</b></div>
                <div className='meta'>Tanker cost  : <b>{e.Tanker_cost}</b></div>
                <div className='meta'>Total cost : <b>{e.Total_cost}</b></div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  </>)
}


const IrrigationCard = (props: any) => {
  return (<>
    {
      props.data.map((e: any, i: number) =>
        <div className={'popCardWrap'} key={i}>
          <div className='header'>
            <div className='farmData flexColumn'>
              <h6><b>{e.Month}</b></h6>

              <div className='meta'>No of Irrigation : <b>{e.No_of_Irrigation}</b></div>
            </div>
            <div className='treeData'>
              <div className='metagroup flexColumn'>
                {e.Month && <div className='meta'>Cost/Hour  : <b>{e.Cost_per_Hour}</b> </div>}
                <div className='meta'>Duration/Hours : <b>{e.Duration_per_Hours}</b></div>
                <div className='meta'>Cost : <b>{e.Cost}</b></div>
                <div className='meta'>Labour cost : <b>{e.Labour_cost}</b></div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  </>)
}