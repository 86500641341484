import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Header } from "../../../../shared/components";
import Practices from "../../widgets/pop/Practices";
import FarmSummary from "../../widgets/farmSummary/FarmSummary";
import './FarmDetails.scss'
import { FarmMngmntAPI } from "../../../../shared/services";
import VarietyCard from "../../widgets/varietyCard/varietyCard";

const FarmDetails = () => {

    const { id } = useParams();
    const [farm, setFarm] = useState({} as any);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const _onHeaderBtnClick = (btn: string) => {
        if (btn === 'back') window.history.back();
    }


    const fetchFarmById = (id: string) => {
        FarmMngmntAPI.getFarmById(id).then((res: {}) => {
            if (res)
                setFarm(res)
        })
    }

    useEffect(() => {
        if (id) fetchFarmById(id as string);
    }, [id]);


    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, [window.innerWidth])

    return (
        <div className='contentContainer'>
            {farm?.farm?.farmId && <Header component='farmDetails' title={'#' + farm?.farm?.farmId} onHeaderBtnClick={_onHeaderBtnClick} />}
            <div className='contentBody'>
                <div className='col-md-3 varietyContainer'>
                    <div className='varietyWiseList'>
                        {farm?.farm?.cultivatedItems.length > 0 && farm?.farm?.cultivatedItems.map((item: any, id: number) =>
                            <div key={id}>
                                <VarietyCard variety={item} />
                            </div>
                        )}
                    </div>
                </div>
                <div className={'col-md-6 ' + (screenWidth > 1400 ? 'inlineColumn maxHeight' : '')}>
                {/* <div className={'col-md-6 ' + (screenWidth > 1000 ? 'maxHeight' : '')}> */}
                    <div className="popDetails mobileVersion inline">
                        <Practices />
                    </div>
                </div>
                {/* <div className={'col-md-3 ' + (screenWidth > 1000 ? 'maxHeight' : '')} style={{display:'inline-block' , verticalAlign:'top'}}> */}
                <div className={'col-md-3 ' + (screenWidth > 1400 ? 'maxHeight inlineColumn' : '')}>
                    {farm && <FarmSummary farm={farm} />}
                </div>
            </div>
        </div>
    );
}

export default FarmDetails;