import { useEffect, useState } from 'react';
import './collectionCard.scss';
import './collectionCard.scss';
import { MessageService, TRADE_MGMT_API } from '../../../../shared/services';
import { MasterAPI } from '../../../../shared/services/MasterAPI';
import { formatDate } from '../../../../configs/Utils';
import { CollectionForm } from '../../forms';
import { useModal } from '../../../../shared/contexts/modalContext';
import CollectionUnits from './collectionUnits';

const CollectionCard = (props: { allocation: any, consignment: any, }) => {

    const [allocation, setAllocation] = useState({} as any);

    useEffect(() => {
        setAllocation(props.allocation);
    }, [props.allocation]);


    const _onAmountAdded = async (data: any) => {
        let qObj = { farmId: props.allocation.farmid.id, collectionMethod: data.harvestMethod, documentation: [], ...data };
        if (data.files) 
            qObj.documentation = await MessageService.getDocsResponseStructureFromPromises(data.files);
        _onAddCollection(qObj);
    }

    const _onAddCollection = (data: any) => {
        TRADE_MGMT_API.addHarvestCollection(data, props.consignment.id).then(res => {
            MessageService.setStatus({ type: 'success', text: 'Collection added!' });
            MessageService.emitSelectedConsignment(props.consignment.id);
        });
    }

    return (
        <div className='allocationGroup'>

            {allocation.farmid &&
                <div className={'header detailsOpen'}>
                    <div className='colData flexColumn'>
                        <div className='meta'>Farm</div>
                        {/* <div className='meta'><b>{allocation.farmid.id}</b></div> */}
                        <h6><b>{allocation.farmid.name}</b></h6>
                    </div>
                    <div className='flex'>
                        <div className='colData flexColumn alignRight'>
                            <div className='meta'>{props.consignment.item.itemType} Variety</div>
                            <div className='highlights'>{props.consignment.item.itemName}</div>
                        </div>
                        <div className='colData flexColumn alignRight'>
                            <div className='meta'>Completion By</div>
                            <div className='highlights'>{formatDate(allocation.collectionCompletionDate)}</div>
                        </div>
                        <div className='colData flexColumn alignRight'>
                            <div className='meta'>Quantity</div>
                            <div className='highlights'><b>{allocation.targetQty.amount}</b> {allocation.targetQty.unit}</div>
                        </div>
                    </div>
                </div>
            }

            {allocation.farmid && <CollectionsSection allocation={allocation} onAmountAdded={_onAmountAdded} />}

        </div>
    )
}

export default CollectionCard;


const CollectionsSection = (props: { allocation: any, onAmountAdded: any }) => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [collections, setCollections] = useState([] as any[]);
    const { openModal, closeModal } = useModal();
    const [pendingCollections, setPendingCollections] = useState<any>(0);

    const _onInitiateCollection = () => {
        openModal(<CollectionForm onFormChange={_onAdd} />, 'md');
    }

    const _onAdd = (data?: any) => {
        closeModal();
        if (data) props.onAmountAdded(data);
    }

    const _isFieldOfficer = () => {
        return localStorage.getItem('role') === 'TRADE_OFFICER' ? true : false;
    }
    const _isCollectionPending = () => {
        return !props.allocation.completed;
    }
    const _extractPendingCollectionAmount = () => {
        let collAmount = collections.reduce((totalC: number, coll: any) => totalC + coll.quantity.amount, 0);
        setPendingCollections(props.allocation.targetQty.amount - collAmount);
    }
    useEffect(() => {
        // if (collections.length > 0)
            _extractPendingCollectionAmount();
    }, [collections])

    useEffect(() => {
        setCollections(props.allocation.collections);
    }, [props.allocation])

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, [window.innerWidth])

    return (
        <>
            {
                <div className='collection'>
                    <div className='collectionUnits inline'>
                        <CollectionUnits collections={collections} />
                    </div>
                    <div className='flexColumn collectionActions inline'>
                        {/* TODO : enable field officer check */}
                        {/* { _isFieldOfficer() && _isCollectionPending() && <div className='primaryBtn' onClick={() => _onInitiateCollection()}>{screenWidth < 1000 ? 'Add' : 'Add Collection'}</div>} */}
                        {_isCollectionPending() 
                            ? <div className='flexColumn alignRight'>
                                { localStorage.getItem('role') != 'EXPORTER' && <div className='primaryBtn floatRight' onClick={() => _onInitiateCollection()}>{screenWidth < 1000 ? 'Add' : 'Add Collection'}</div>}
                                <div className='errorColor pending'>Pending : <b>{pendingCollections}</b> <i>{props.allocation.targetQty.unit}</i></div>
                            </div>
                            : <div className='statusMeta'>Completed</div>
                        }
                    </div>
                </div>
            }
        </>
    )

}