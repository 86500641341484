import React, { useEffect, useState } from 'react'
import { adminNotificationTabs, getCurrentRole, notificationTabs } from '../../../configs/Utils';
import Header from '../../components/Header';
import { MessageService, TRADE_MGMT_API, UserMngmntAPI } from '../../services';
import { ConsignmentCard } from '../../../modules/TradeManagementModule/widgets';
import { ConsignmentDetails } from '../../../modules/TradeManagementModule/components';
import { useDrawer } from '../../contexts/drawerContext';
import ScrollableTabsHeader from '../../components/scrollableTabsHeader/scrollableTabsHeader';
import { InviteDashboard } from '../../components';
import './notificationDashboard.scss';
import { ADMIN_MGMT_API } from '../../services/AdminManagementAPI';

const NotificationDashboard = () => {

    const role = getCurrentRole();
    const [headerCount, setHeaderCount] = useState(0)
    const [pendingConsignments, setPendingConsignments] = useState([]);
    const [selected, setSelected] = useState(0);
    const [selectedTab, setSelectedTab] = useState<any>();
    const [pendingInvites, setPendingInvites] = useState([]);
    const [approvedInvites, setApprovedInvites] = useState([]);
    const [pendingExporter, setPendingExporter] = useState([]);


    const _onHeaderBtnClick = (btn: string, data?: any) => { }

    const _onApprovalStatus = (status: boolean) => {
        if (status)
            if (role === 'PLATFORM_ADMIN') {
                _fetchPendingExporters()
            } else {
                selected === 0 ? fetchPendingContracts() : _fetchBoth()
            }
    }

    const _fetchBoth = () => {
        fetchApprovedInvites();
        fetchPendingInvites();
    }

    const fetchPendingInvites = () => {
        UserMngmntAPI.pendingLinkRequest().then(res => {
            let arr = res.map((e: any) => {
                return { ...e, 'status': false }
            })
            setPendingInvites(arr)
        })
    }

    const fetchApprovedInvites = () => {
        UserMngmntAPI.approvedLinkRequest().then(res => {
            let arr = res.map((e: any) => {
                return { ...e, 'status': true }
            })
            setApprovedInvites(arr)
        })
    }

    const fetchPendingContracts = () => {
        TRADE_MGMT_API.getPendingContracts().then((response: any) => {
            if (response) {
                setHeaderCount(response.length);
                MessageService.emitPendingConsignmentsCount(response.length);
                setPendingConsignments(response);
            }
        });
    }

    const handleChange = (tab: any) => {
        let newValue = notificationTabs.findIndex((e: any) => e.name === tab.name);
        setSelected(newValue);
        setSelectedTab(tab)
    };

    const handleAdminChange = (tab: any) => {
        let newValue = adminNotificationTabs.findIndex((e: any) => e.name === tab.name);
        setSelected(newValue);
        setSelectedTab(tab)
    };

    const _fetchPendingExporters = () => {
        ADMIN_MGMT_API.fetchInactiveUsers('EXPORTER').then((res: any) => {
            if (res) {
                setHeaderCount(res.length)
                setPendingExporter(res)
            }
        })
    }

    useEffect(() => {
        if (role === 'TRADER' || role === 'EXPORTER') {
            fetchPendingContracts()
            _fetchBoth()
        }
        if (role === 'PLATFORM_ADMIN') {
            _fetchPendingExporters()
        }
    }, [role])


    const SelectionDisplay = () => {
        if (selected === 0)
            return <PendingConsignment pendingConsignments={pendingConsignments} approvalStatus={_onApprovalStatus} />
        else if (selected === 1) {
            let arr = [...pendingInvites, ...approvedInvites]
            arr.sort((a: any) => {
                return a.status
            })
            console.log('arr ===', arr);

            return <InviteDashboard invitesArr={arr} approvalStatus={_onApprovalStatus} />
        }
        else
            return <></>
    }

    const AdminSelectionDisplay = () => {
        console.log('hit', selected);

        if (selected === 0)
            return <InviteDashboard invitesArr={pendingExporter} approvalStatus={_onApprovalStatus} />
        else
            return <></>
    }


    return (
        <div className='contentContainer'>
            <Header component='notification' count={headerCount} onHeaderBtnClick={_onHeaderBtnClick} />
            {(role === 'TRADER' || role === 'EXPORTER') &&
                <div className='maxHeight'>
                    <div className='headerPart'>
                        <ScrollableTabsHeader tabs={notificationTabs} activeTab={selectedTab} onTabClick={handleChange} type='general' />
                    </div>
                    <div className='selectionCardWrap'>
                        <SelectionDisplay />
                    </div>
                </div>
            }
            {
                role === 'PLATFORM_ADMIN' &&
                <div className='maxHeight'>
                    <div className='headerPart'>
                        <ScrollableTabsHeader tabs={adminNotificationTabs} activeTab={selectedTab} onTabClick={handleAdminChange} type='general' />
                    </div>
                    <div className='selectionCardWrap'>
                        <AdminSelectionDisplay />
                    </div>
                </div>
            }
        </div>
    )
}

export default NotificationDashboard

const PendingConsignment = (props: { pendingConsignments: any, approvalStatus: any }) => {

    const { openDrawer, closeDrawer } = useDrawer();

    const onSelectConsignment = (consignment: any) => {
        openDrawer(<ConsignmentDetails consignment={consignment} />);
    }

    return (
        <div className='contentBody displayBlock scrollable maxHeight'>
            {
                props.pendingConsignments.map((consignment: any, index: number) => (
                    <div className='notificationCardInline inline flex' key={index} onClick={() => onSelectConsignment(consignment)}>
                        <ConsignmentCard data={consignment} readonly showApprovalState emitApprovalStatus={props.approvalStatus} />
                    </div>
                ))
            }
        </div>
    )
}

