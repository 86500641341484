import { createContext, useContext, useEffect, useState } from 'react';
import { APP_CONSTANTS } from '../../configs/Utils';

const ModalContext = createContext<any>(null);
const ModalWidth: any = {
  xl: '95%',
  lg: '70%',
  md: '50%',
  sm: '30%'
}

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }: any) => {
  const [modalContent, setModalContent] = useState(null);
  const [width, setWidth] = useState<any>();
  const [screenWidth] = useState(window.innerWidth);

  const openModal = (content: any, width: typeof ModalWidth) => {
    setModalContent(content);
    width ? setWidth(ModalWidth[width]) : setWidth(ModalWidth.md);
    if (screenWidth < 1000) {
      setWidth(ModalWidth.xl);
    }
  };

  const closeModal = () => {
    setModalContent(null);
  };


  return (
    <ModalContext.Provider value={{ modalContent, openModal, closeModal }}>
      {children}
      {modalContent && (
        <div className="modal-overlay">
          <div className="modal-content relative" style={{ width: width }}>
            <img src={APP_CONSTANTS.IMAGE_PATH + 'cancel.png'} className='modalCancel absolute' alt="close" onClick={closeModal} />
            {modalContent}
          </div>
        </div>
      )}
    </ModalContext.Provider>
  );
};
